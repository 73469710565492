@import url("https://fonts.googleapis.com/css2?family=Volkhov:ital,wght@0,400;0,700;1,400;1,700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,100..900;1,100..900&display=swap");

.offline {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.headecard {
  width: 100%;
  height: 37.5rem;
  border-end-end-radius: 4.375rem;
  border-bottom-left-radius: 4.375rem;
  background-color: #4e99e9;
}
.ProductDetailsVerticalSlider{
  margin-top: 20px;
  height: 550px !important;
  min-width: 110px;
  overflow-x: hidden;
  margin-left: 40px !important;

}


.ProductDetailsVerticalSlider::-webkit-scrollbar {
    width: 0px;
    background-color: rgba(24, 44, 79, 0.2);

    
}

.ProductDetailsVerticalSlider::-webkit-scrollbar-thumb {
    background-color: rgba(24, 44, 79, 1);

    
}
.logo {
  width: 6.25rem;
}
.headerCover{
  margin-top: .9375rem;
  background: url(../src/Assets/Images/HomeCover.png);
  height: 50vh;
  background-position: top center;
  background-size: cover;
}
.InputContainer {
  height: 2.1875rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  border-radius: 0.3125rem;
  overflow: hidden;
  cursor: pointer;
  padding-left: 15px;
  box-shadow: 0.125rem 0.125rem 0.625rem rgba(0, 0, 0, 0.075);
  border: 1px black solid;
}
.main-cover{
  background: url(../src/Assets/Images/Cart\ Img.png);
  height: 30vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-position: center center;
  background-size: cover;
}

.input {
  width: 18.75rem;
  height: 100%;
  border: none;
  outline: none;
  font-size: 0.9em;
  caret-color: rgb(255, 81, 0);
}

.labelforsearch {
  cursor: text;
  padding: 0rem 0.75rem;
}

.searchIcon {
  width: 0.9375rem;
  cursor: pointer;
}

.searchIcon path {
  fill: rgb(114, 114, 114);
}

.navbrdr {
  width: 100%;
  height: 1.875rem;
  background-color: #044f5c;
}
.navbrdr2 {
  width: 100%;
  background-color: #044f5c;
}
.headerimg1 {
  width: 24.5rem;
  height: 44.6875rem;
  background-color: #ebebf0;
  display: flex;
  justify-content: center;
  align-items: end;
}
.headerImg2Top {
  width: 26.625rem;
  height: 9.375rem;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
}
.ultimate {
  font-weight: bold;
  text-align: center;
  font-size: 5.625rem;
  width: 100%;
  color: #484848;
}

.sale {
  text-align: center;
  line-height: 7.5rem;
}
.headerImg2Text h4 {
  color: transparent;
  -webkit-text-stroke-width: 0.125rem;
  -webkit-text-stroke-color: black;
  font-size: 12.5rem;
}
@font-face {
  font-family: againstRegular;
  src: url(Assets/fonts/against-regular.ttf);
}
@font-face {
  font-family: AlteHaasGrotesk;
  src: url(Assets/fonts/alte-haas-grotesk.regular.ttf);
}

.newcollection {
  color: #484848;
  font-size: 1.375rem;
  text-align: center;
  line-height: 6.25rem;
  font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
}


.custum-file-upload {
  height: 80px;
  width: 80px;
  border-radius: 50% !important;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
  border: 2px dashed #e8e8e8;
  background-color: #044f5c;
  padding: 1.5rem;
  border-radius: 10px;
  box-shadow: 0px 48px 35px -48px #e8e8e8;
}
.custum-file-upload2 {
  height: 80px;
  width: 80px;
  border-radius: 50% !important;
  display: flex;
  flex-direction: column;
  align-items: space-between;
  gap: 20px;
  cursor: pointer;
  align-items: center;
  justify-content: center;
}
.profileImg img{
  border-radius: 50% !important;
}
.edit-img{
  position: absolute;
  top: 10px;
  font-size: 20px;
  left: 61%;
  cursor: pointer;
}
.custum-file-upload .icon {
  display: flex;
  align-items: center;
  justify-content: center;
}

.custum-file-upload .icon svg {
  height: 25px;
  fill: #e8e8e8;
}

.custum-file-upload input {
  display: none;
}

.main-clr {
  background-color: #044f5c !important;
}
.address-w{
  width: 95% !important;
}
.address-w2{
  width: 85% !important;
}
.headerimg3 {
  width: 24.5rem;
  height: 44.6875rem;
  background-color: #c0b2b1;
  display: flex;
  justify-content: center;
  align-items: end;
}
.totalPriceDiv{
  width: 50% !important;
  text-align: end !important;
}
.addToCartBtn:hover{
  background-color: #044f5c !important;
}
.numOfCartItems {
  width: 1.125rem;
  height: 0.625rem;
  padding: 0.5625rem 0.3125rem;
  background-color: #d4d0cb;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: -0.625rem;
  right: 20%;
  font-weight: bold;
}
.filterbutton{
  z-index: 999999 !important;
  right: 37%;
  top: 90%
}
.cursor-pointer {
  cursor: pointer;
}

.sec-contanier {
  width: 100%;
  background: #fafafa;
}
.sliderDiv {
  width: 500px;
  height: 300px;
  background-color: white;
  margin-right: 50px;
  font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
}

.sliderline {
  width: 150px;
  height: 4px;
  background-color: black;
}
.sliderline3 {
  width: 114px;
  height: 4px;
  background-color: black;
}

.sec-contanier h3 {
  font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
}
.sec-contanier p {
  font-family: AlteHaasGrotesk, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 20px;
}

.third-contanier {
  width: 100%;
  background: #fafafa;
}
.third-contanier {
  height: 400px;
}
.OurProducts {
  width: 100%;
  background: white;
}
.OurProducts h3 {
  font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
}
.OurProducts h3::after {
  content: "";
  position: absolute;
  width: 41%;
  height: 4px;
  background-color: black;
  top: 50%;
  right: -20px;
}
.OurProducts h3::before {
  content: "";
  position: absolute;
  width: 41%;
  height: 4px;
  background-color: black;
  top: 50%;
  left: -20px;
}
.ourProductstext p {
  font-size: 18px;
  font-weight: 500;
}
.saleCard {
  min-width: 55px;
  max-width: 100px;
  height: 27px;
  background-color: #da3f3f;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  top: 0;
  left: 81%;
  font-size: 12px;
  text-align: center;
}
.shape1 {
  background: #fafafa;
}
.shape2 {
  background: #fafafa;
}
.font-sm {
  font-size: 10px;
}
.slidertest1 {
  width: 500px;
  height: 400px;
  background-color: #044f5c;
}
.card {
  border-radius: 10px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  width: 100px;
  height: 100px;
  font-size: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
}
.button {
  margin: 10px;
  background-color: white;
  border: transparent;
  padding: 7px;
  height: 50px;
  width: 50px;
  border-radius: 500px;
}
.main-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}
.wrapper {
  display: flex;
  margin: 20px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.main-color-section {
  background-color: #f2f2f2;
}
.circleImg {
  width: 60px !important;
  height: 60px !important;
}

.review-slider h3 {
  font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
  position: relative;
}
.review-slider h3::after {
  content: "";
  position: absolute;
  width: 35%;
  height: 4px;
  background-color: black;
  top: 50%;
  right: -20px;
}
.review-slider h3::before {
  content: "";
  position: absolute;
  width: 35%;
  height: 4px;
  background-color: black;
  top: 50%;
  left: -20px;
}



.rating-clr {
  color: #fca120;
}
.storeDiv{
  border: solid rgb(4, 79, 92) 3px !important ;
}
.subscribe p {
  color: #8a8a8a;
}
.subscribe h4 {
  font-family: AlteHaasGrotesk;
  color: #484848;
  font-size: 35px;
}
.subscribe a {
  font-family: AlteHaasGrotesk;
  color: #8a8a8a;
  font-size: 20px;
  text-decoration: none;
}
.subscribeCard {
  box-shadow: #f2f2f2 27px 70px 30px;
}
.subscribe button {
  background: #044f5c;
  padding: 10px 25px;
  font-size: 13px;
}
.Footer {
  background: #f9fafb;
}
.Footer ul {
  list-style: none;
}
.Footer a {
  color: #6b7280;
  text-decoration: none;
}
.footer-main-clr {
  color: #6b7280;
}
.pag-test{
  background-color: #044f5c !important;
}
.fillters .checkbox-container {
  display: inline-block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 16px;
  user-select: none;
}

.fillters .custom-checkbox {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.active{
  background-color: white !important;
}
.fillters .custom-checkbox2 {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  background-color: red !important;
}

.fillters .checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  background-color: #eee;
  border-radius: 4px;
  transition: background-color 0.3s;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.fillters .checkmark:after {
  content: "";
  position: absolute;
  display: none;
  left: 9px;
  top: 5px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  transform: rotate(45deg);
}

.fillters .custom-checkbox:checked ~ .checkmark {
  background-color: rgb(4, 79, 92);
  box-shadow: 0 3px 7px rgb(4, 79, 92);
}

.fillters .custom-checkbox:checked ~ .checkmark:after {
  display: block;
}

@keyframes checkAnim {
  0% {
    height: 0;
  }

  100% {
    height: 10px;
  }
}

.custom-checkbox:checked ~ .checkmark:after {
  animation: checkAnim 0.2s forwards;
}
.filterProducts {
  font-family: "Volkhov", serif;
}
.saleCard2 {
  width: 55px;
  height: 27px;
  background-color: #da3f3f;
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;
  position: absolute;
  top: 0;
  right: -5%;
  font-size: 12px;
  text-align: center;
}
.blue {
  color: blue;
}
.brown {
  color: brown;
}
.green {
  color: green;
}
.gray {
  color: gray;
}
.orange {
  color: orange;
}
.pink {
  color: pink;
}
.red {
  color: red;
}
.tan {
  color: tan;
}
.navclr {
  color: #044f5c;
  background-color: #044f5c;
}

.card-conteiner {
  cursor: default;
  --color-primary: #275efe;
  --color-headline: #3f4656;
  --color-text: #99a3ba;
}

/* .card-content .values div, .card-content .current-range div {
  display: inline-block;
  vertical-align: top;
} */

/* .card-content .values {
  margin: 0;
  font-weight: 500;
  color: var(--color-primary);
} */

/* .card-content .values > div:first-child {
  margin-right: 2px;
} */

/* .card-content .values > div:last-child {
  margin-left: 2px;
} */

/* .card-content .current-range {
  display: block;
  color: var(--color-text);
  margin-top: 8px;
  font-size: 14px;
} */

/* .card-content .slider {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
  font-size: .6em;
  color: var(--color-text);
} */

/* Range Slider */
.input-ranges[type="range"] {
  width: 150px;
  height: 30px;
  overflow: hidden;
  outline: none;
}

.input-ranges[type="range"],
.input-ranges[type="range"]::-webkit-slider-runnable-track,
.input-ranges[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  background: none;
  border-radius: 10%;
}

.input-ranges[type="range"]::-webkit-slider-runnable-track {
  width: 200px;
  height: 9px;
  background: rgb(4, 79, 92);
  border-radius: 10%;
}

.input-ranges[type="range"]:nth-child(2)::-webkit-slider-runnable-track {
  background: rgb(4, 79, 92);
}

.input-ranges[type="range"]::-webkit-slider-thumb {
  position: relative;
  height: 15px;
  width: 15px;
  margin-top: -4px;
  background: #fff;
  border: 1px solid var(--color-headline);
  border-radius: 25px;
  cursor: pointer;
  z-index: 1;
  transition: 0.5s;
  -webkit-transition: 0.5s;
  -moz-transition: 0.5s;
  -ms-transition: 0.5s;
  -o-transition: 0.5s;
}

.input-ranges[type="range"]::-webkit-slider-thumb:hover {
  background: white;
}

.input-ranges[type="range"]::-webkit-slider-thumb:active {
  cursor: grabbing;
}

.input-ranges[type="range"]:nth-child(1)::-webkit-slider-thumb {
  z-index: 2;
}

.rangeslider {
  font-family: sans-serif;
  font-size: 14px;
  position: relative;
  height: 20px;
  width: 200px;
  display: inline-block;
  margin-top: -5px;
}

.rangeslider input {
  position: absolute;
}

.rangeslider span {
  position: absolute;
  margin-top: 20px;
  left: 0;
}

.rangeslider .right {
  position: relative;
  float: right;
  margin-right: -5px;
  background: white;
}
.swiper {
  width: 100%;
  height: 100%;
}
.slide{
  transform: scale(0.5);
  transition: transform 300ms;
  opacity: 0.5;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.activeSlide{
    transform: scale(1.1);
    opacity: 1;

}
/* Hide the default checkbox */
/* .container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  
 } */
 
 /* .container {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
 } */
 
 .checkmark {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: red;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 0px 17px #c7bbb8;
 }
 .checkmark2 {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: rgb(233, 241, 241);
  box-shadow: 0px 0px 17px #c7bbb8;
  border-radius: 50%;
  z-index: -999999999999999999999999999999999999999999999999999999999999999999999999999999999;
 }
 .check {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: rgb(233, 241, 241);
  box-shadow: 0px 0px 17px #c7bbb8;
  border-radius: 50%;
  z-index: -9999999999999999999999999999999999999999999999999999999999999999999999999999;
 }
 .checkmark3 {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: yellow;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 0px 17px #c7bbb8;
 }
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after , .checkmark3:after  {
  content: "";
  position: absolute;
  display: none !important;
 }
 
 /* Show the checkmark when checked */
 .container input:checked ~ .checkmark1:after ,  .container input:checked ~ .checkmark2:after 
 , .container input:checked ~ .checkmark3:after {
  display: block !important;
 }

 
 /* Style the checkmark/indicator */
 .container .checkmark1:after , .checkmark2:after , .checkmarks:after {
  content: "";
  position: absolute;
  left: -4px !important;
  top: -4px !important;
  width: 1.8em !important;
  height: 1.8em !important;
  border: solid #044f5c !important;
  transform: rotate(180deg) !important;
  border-radius: 50% !important;
 }


 /* Hide the default checkbox */
.containers input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  
 }
 
 .containers {
  display: block;
  position: relative;
  cursor: pointer;
  font-size: 20px;
  user-select: none;
 }
 
 /* Create a custom checkbox */
 .checkmarks1 {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: red;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 0px 17px #c7bbb8;
 }
 .checkmarks2 {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: teal;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 0px 17px #c7bbb8;
 }
 .checkmarks3 {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: yellow;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 0px 17px #c7bbb8;
 }
 .checkmarks4 {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: black;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 0px 17px #c7bbb8;
 }
 .checkmarks5 {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: green;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 0px 17px #c7bbb8;
 }
 .checkmarks6 {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: tomato;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 0px 17px #c7bbb8;
 }
 .checkmarks7 {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: wheat;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 0px 17px #c7bbb8;
 }
 .checkmarks8 {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: white;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 0px 17px #c7bbb8;
 }
 .checkmarks9 {
  position: relative;
  top: 0;
  left: 0;
  height: 1.3em;
  width: 1.3em;
  background-color: burlywood;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 15px;
  box-shadow: 0px 0px 17px #c7bbb8;
 }
 

 /* Create the checkmark/indicator (hidden when not checked) */
 .checkmarks1:after, .checkmarks2:after , .checkmarks3:after ,
  .checkmarks4:after , .checkmarks5:after , .checkmarks6:after ,
   .checkmarks7:after , .checkmarks8:after , .checkmarks9:after {
  content: "";
  position: absolute;
  display: none !important;
 }
 
 /* Show the checkmark when checked */
 .containers input:checked ~ .checkmarks1:after ,  .containers input:checked ~ .checkmarks2:after 
 , .containers input:checked ~ .checkmarks3:after , .containers input:checked ~ .checkmarks4:after 
 , .containers input:checked ~ .checkmarks5:after  , .containers input:checked ~ .checkmarks6:after
 , .containers input:checked ~ .checkmarks7:after  , .containers input:checked ~ .checkmarks8:after
 , .containers input:checked ~ .checkmarks9:after {
  display: block !important;
 }
 
 /* Style the checkmark/indicator */
 .containers .checkmarks1:after , .checkmarks2:after , .checkmarks3:after ,
 .checkmarks4:after , .checkmarks5:after , .checkmarks6:after , 
 .checkmarks7:after , .checkmarks8:after , .checkmarks9:after {
  left: 0.45em;
  top: 0.25em;
  width: 0.25em;
  height: 0.5em;
  border: solid rgb(233, 222, 222);
  border-width: 0 0.15em 0.15em 0;
  transform: rotate(45deg);
 }


 .active-content{
  display: inline-block !important;
 }
 .tabs{
  padding: 5px 10px;
  border-top-left-radius: 15px;
  border-top-right-radius: 15px;
 }
 .active-tabs {
  background-color: rgb(4, 79, 92);
  border-bottom: 1px solid transparent;
  color: white;
 }
 .content{
  display: none !important;
 }
.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: flex;
  justify-content: center;
  align-items: center;
}

.swiper-slide img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.hurryUp {
  width: 100%;
}
.huryupcounter{
  color: #ff706b;

}
.huryupText {
  font-family: "Volkhov", serif;
}
.progress {
  height: 7px;
}
.progress-bar {
  width: 10%;
  background: #ef2d2d;
}
.prog h6 {
  color: #666666;
  font-family: "Jost", sans-serif;
}
.sizebox{
  padding: 0px 10px;
  background-color: rgb(4, 79, 92)!important;
  color: white !important;


}





.mySwiper1 {
  height: fit-content;
}
.review-slider-div{
  height: 22vh !important;
  margin-top: 40px !important;  
}
.mySwiper2 {
  height: 170px;
  width: 150px;
}
.mySwiper2 img{
  padding: 5px 15px  !important;
}
.rounded-select{
  border: solid 10px black;
}
.roundedClrOut {
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-left: 4px;
}
.roundedClr {
  width: 30px;
  height: 30px;
  border-radius: 50%;
}
.roundedClr3 {
  background: #ffd1dc;
}
.roundedClr1 {
  background: #8db4d2;
}
.Quantity {
  border: 1px solid #eeeeee;
  font-family: "Jost", sans-serif;
  display: flex;
  justify-content: space-between;
  font-size: 18px;
  align-items: center;
}

.Delivery h6,
.Shipping h6,
.QuantityText h6,
.colorName,
.paymentMethods h6 {
  font-family: "Volkhov", serif;
}
.paymentMethods {
  width: 100%;
  background: #f8f8f8;
  height: 16%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
}
.Related h3 {
  font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
  text-align: center;
}

.loading{
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: #e0e0e0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999999999999;
  overflow: hidden;

}

.Related h3::after {
  content: "";
  position: absolute;
  width: 39%;
  height: 4px;
  background-color: black;
  top: 50%;
  right: -20px;
}
.Related h3::before {
  content: "";
  position: absolute;
  width: 39%;
  height: 4px;
  background-color: black;
  top: 50%;
  left: -20px;
}
.slick-prev:before,
.slick-next:before {
  color: black;
}
.slick-next{
  margin-right: 10px !important;
}
.slick-prev{
  margin-left: 10px !important;
}
.wishlistbtn {
  background: #044f5c;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.producthead {
  position: relative;
  overflow: hidden !important;
}
.product-layer {
  position: absolute;
  bottom: 20%;
  transform: translateY(340%);
  transition: all 0.5s;
  display: flex !important;
  justify-content: center !important;
  width: 100% !important;
  
}
.product:hover .product-layer {
  transform: translateY(180%);
}

.login {
  font-family: AlteHaasGrotesk, "Open Sans", "Helvetica Neue", sans-serif;
}
.Available{
  height: 70vh;
}
.login p {
  color: #d4d0cb;
}
.fa-user {
  position: absolute;
  left: 3%;
  top: 55%;
  color: #d4d0cb;
}
.fa-lock {
  position: absolute;
  left: 3%;
  top: 55%;
  color: #d4d0cb;
}
.fa-eye {
  position: absolute;
  right: 3%;
  top: 55%;
  color: #d4d0cb;
  cursor: pointer;
}
.ForgotPassword {
  text-align: end;
  color: #d4d0cb;
}
.LoginBtn button {
  background: #044f5c;
  border-radius: 12px;
  font-family: AlteHaasGrotesk, "Open Sans", "Helvetica Neue", sans-serif;
}
.noAccount {
  font-size: 13px;
}
.fa-phone {
  position: absolute;
  left: 3%;
  top: 55%;
  color: #d4d0cb;
}
.cover-text {
  color: white;
  font-size: 40px;
  font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
}
.cartCircle {
  width: 10px;
  height: 10px;
  border: solid 2px black;
  border-radius: 50%;
}
.cartCircle2 {
  width: 10px;
  height: 10px;
  border: solid 2px #d4d0cb;
  border-radius: 50%;
}
.cartCircle3 {
  width: 10px;
  height: 10px;
  border: solid 2px #d4d0cb;
  border-radius: 50%;
}
.cartLine {
  width: 45%;
  height: 2px;
  background-color: #d4d0cb;
}
.customCheckBoxHolder {
  margin: 5px;
  display: flex;
}

.customCheckBox {
  width: fit-content;
  position: relative;
  overflow: hidden;
  cursor: pointer;
  user-select: none;
  padding: 2px 8px;
  background-color: rgba(0, 0, 0, 0.16);
  border-radius: 0px;
  color: rgba(255, 255, 255, 0.7);
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 300ms;
  transition-property: color, background-color, box-shadow;
  display: flex;
  height: 32px;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.15) 0px 2px 1px 0px inset, rgba(255, 255, 255, 0.17) 0px 1px 1px 0px;
  outline: none;
  justify-content: center;
  min-width: 55px;
}

.customCheckBox:hover {
  background-color: #2c2c2c;
  color: white;
  box-shadow: rgba(0, 0, 0, 0.23) 0px -4px 1px 0px inset, rgba(255, 255, 255, 0.17) 0px -1px 1px 0px, rgba(0, 0, 0, 0.17) 0px 2px 4px 1px;
}

.customCheckBox .inner {
  font-size: 18px;
  font-weight: 900;
  pointer-events: none;
  transition-timing-function: cubic-bezier(0.25, 0.8, 0.25, 1);
  transition-duration: 300ms;
  transition-property: transform;
  transform: translateY(0px);
}

.customCheckBox:hover .inner {
  transform: translateY(-2px);
}

.customCheckBoxWrapper:first-of-type .customCheckBox {
  border-bottom-left-radius: 5px;
  border-top-left-radius: 5px;
  border-right: 0px;
}

.customCheckBoxWrapper:last-of-type .customCheckBox {
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  border-left: 0px;
}

.customCheckBoxInput {
  display: none;
}

.customCheckBoxInput:checked + .customCheckBoxWrapper .customCheckBox {
  background-color: rgb(4, 79, 92);
  color: white;
  box-shadow: rgba(0, 0, 0, 0.23) 0px -4px 1px 0px inset, rgba(255, 255, 255, 0.17) 0px -1px 1px 0px, rgba(0, 0, 0, 0.17) 0px 2px 4px 1px;
}

.customCheckBoxInput:checked + .customCheckBoxWrapper .customCheckBox .inner {
  transform: translateY(-2px);
}

.customCheckBoxInput:checked + .customCheckBoxWrapper .customCheckBox:hover {
  background-color: black;
  box-shadow: rgba(0, 0, 0, 0.26) 0px -4px 1px 0px inset, rgba(255, 255, 255, 0.17) 0px -1px 1px 0px, rgba(0, 0, 0, 0.15) 0px 3px 6px 2px;
}

.customCheckBoxWrapper .customCheckBox:hover .inner {
  transform: translateY(-2px);
}
.main-clr-text {
  color: #d4d0cb;
}
.main-clr-text2 {
  color: #044f5c !important;
}
.testing input[type='checkbox']{
  border: none !important;
  
}


.rating:not(:checked) > input {
  position: absolute;
  appearance: none;
}

.rating:not(:checked) > label {
  float: right;
  cursor: pointer;
  font-size: 30px;
  color: #666;
}

.rating:not(:checked) > label:before {
  content: "★";
}

.rating > input:checked + label:hover,
.rating > input:checked + label:hover ~ label,
.rating > input:checked ~ label:hover,
.rating > input:checked ~ label:hover ~ label,
.rating > label:hover ~ input:checked ~ label {
  color: #e58e09;
}

.rating:not(:checked) > label:hover,
.rating:not(:checked) > label:hover ~ label {
  color: #ff9e0b;
}

.rating > input:checked ~ label {
  color: #ffa723;
}

.cartIncDec {
  width: 80px;
  height: 30px;
  border: #d4d0cb solid 1px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
}
.backButton:hover {
  background-color: #044f5c !important;
}
.red-clr {
  color: #044f5c;
}
.aboutUs h6 {
  font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
}
.squareIcon {
  width: 35px;
  height: 35px;
  border: #d4d0cb solid 1px;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.font-md {
  font-size: 15px;
}
.swiper-button-next {
  background-image: url(../src/Assets/Icons/icons8-forward-52.png) !important;
  width: 100px !important;
  text-align: end !important;
  background-repeat: no-repeat;
  background-size: contain;
  margin-right: -65px !important;
  margin-top: 0px !important;
}

.swiper-button-next::after {
  display: none;
}
.swiper-button-prev{
  background-image: url(../src/Assets/Icons/icons8-back-52.png) !important;
  width: 100px !important;
  text-align: end !important;
  background-repeat: no-repeat;
  background-size: contain;
  margin-top: 0px !important;
  margin-left: -15px !important;
}

.swiper-button-prev::after {
  display: none;
}
.offer{
  background: url(../src/Assets/Images/offercover.png);
  background-size: cover;
  background-position: center center;
  height: 45vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
.buttonaddnewaddress{
  padding: 6.5px 15px !important ;

}

.shop {
  position: relative;
  display: inline-block;
}

.dropdown-content {
  display: none;
  position: absolute;
  background-color: #f9f9f9;
  min-width: 100px;
  box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
  padding: 12px 16px;
  z-index: 999;
}
.dropdown-content h4{
  font-family: againstRegular;
  font-size: 15px;
  font-weight: bold;
}
.shop:hover .dropdown-content {
  display: block;
}
.loginh3 h3::after {
  content: "";
  position: absolute;
  width: 35%;
  height: 4px;
  background-color: #d4d0cb;
  top: 50%;
  right: 30px;
}
.loginh3 h3::before {
  content: "";
  position: absolute;
  width: 35%;
  height: 4px;
  background-color: #d4d0cb;
  top: 50%;
  left: 30px;
}
.error {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  width: 320px;
  padding: 10px;
  margin-bottom: 0 !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
  background: #EF665B;
  border-radius: 8px;
  box-shadow: 0px 0px 5px -3px #111;
}

.error__icon {
  margin-bottom: 0 !important;
  width: 15px;
  height: 15px;
  transform: translateY(-6px);
  margin-right: 12px;
}

.error__icon path {
  fill: #fff;

}

.error__title {
  font-weight: 500;
  font-size: 12px;
  color: #fff;
}

.error__close {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin-left: auto;
}

.error__close path {
  fill: #fff;
}
.btn-offer{
  position: absolute;
  z-index: 5;
  top: 80%;
  left: 80%;
}


.rating-inputs input[type="checkbox"] {
  display: none;
}

.rating-inputs label svg {
  width: 30px;
  height: 44px;
  fill: none;
  stroke: #FFC107;
  stroke-width: 2px;
  margin-left: 5px;
}

/* .active{
  background-color: red !important; 
  border-color: red !important; 
  color: white !important;
} */

.rating-inputs input[type="checkbox"]:checked + label svg {
  fill: #FFC107;
  animation: pop_42 0.5s ease-out;
}

@keyframes pop_42 {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.5);
  }

  100% {
    transform: scale(1);
  }
}

.wishlistbtn2 {
  background: #044f5c;
  width: 45px;
  height: 40px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 20px !important;
}
.pagination-item{
  background: #044f5c !important;
  border-radius: 50%;
}

@media screen and (max-width: 762px) {
  .wishlistbtn {
    background: #044f5c;
    width: 30px !important;
    height: 30px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;

  }
  .productimg{
    width: 500px !important;
    height: 300px !important;
  }

  .wishlistbtn2 {
    background: #044f5c;
    width: 45px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 20px !important;
  }
  .product-layer {
    position: absolute;
    bottom: 20%;
    transform: translateY(340%);
    transition: all 0.5s; 
    display: flex !important;
    justify-content: center !important;
    align-items: center !important;
    width: 100% !important;
  }
  .product-layer .productButton{
    padding: 3px 5px !important;
    font-size: 15px;
  }
.productButton{
  width: 200px !important;
  height: 50px !important;
}
  .product-layer .img1{
    width: 120px !important;
    height: 40px !important;
  }
    .product-layer .img2{
    width: 200px !important;
    height: 50px !important;
  }
  .product:hover .product-layer {
    transform: translateY(100%);
  }

  .Quantity{
    width: 200px !important;
    padding: 8px 0;
  }
  .slide{
    transform: scale(1);
    transition: transform 300ms;
    opacity: 1;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
  }

  .slide img{
    width: 100px ;
    height: 150px;
    /* margin-bottom: 40px; */
  }
  .btn-offer{
    position: absolute;
    z-index: 5;
    top: 80%;
    left: 47%;
  }
  .div-test{
    height: 20vh !important;
    margin-top: 25px !important;
  }
  .producthead img{
    width: 100%;
    height: 250px;
    display: flex;
    justify-content: center;
  }
  .product h4{
    font-size: 15 px ;
  }
  .product p{
    font-size: 14px;
  }
  .rangeslider{
    width: 100% !important;
  }
  .card-conteiner{
    width: 100% !important;
  }
  .mySwiper1{
    height: 21vh !important;
  }
  .loginh3 h3::after {
    content: "";
    position: absolute;
    width: 25%;
    height: 4px;
    background-color: #d4d0cb;
    top: 50%;
    right: 50px;
  }
  .loginh3 h3::before {
    content: "";
    position: absolute;
    width: 25%;
    height: 4px;
    background-color: #d4d0cb;
    top: 50%;
    left: 50px;
  }
  .loginCover{
    display: none;
  }
  .tablebutton{
    width: 50px;
    font-size: 10px;
  }
  .tablebutton2{
    width: 10px !important;
    font-size: 10px;
    display: flex;
    justify-content: center;
    justify-items: center;
    align-items: center; 
    align-content: center;
    margin-left: 0px !important;
  }
  .table{
    font-size: 9px;
  }
 .checkoutDropdown{
  width: 100% !important;
  font-size: 8px;
 }.checkoutdrop{
  margin-bottom: 40px;
 }
  .registerCover{
    display: none;
  }
  .boxlogin{
    box-shadow: none  !important;
  }
  .boxregister{
    box-shadow: none  !important;
  }
  .filterProducts{
    width: 100% !important;
    padding-left: 0 !important;
    padding-right: 0 !important;
    margin-left: 0 !important;
    margin-right: 0 !important;
    margin: 0 auto !important;
  }
  .navbrdr{
    display: none !important;
  }
  .aboutUs img {
    width: 200px;
  }
  .aboutUs  p{
    font-size: 13px !important;
  }
  .sliderdetails{
    display: none !important;
  }
  .navIcons{
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-bottom: .625rem;
  }
  .saleCard2 {
    width: 35%;
    height: 30px;
    background-color: #da3f3f;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    top: 0;
    right: -2%;
    font-size: 12px;
    text-align: center;
  }
  .CategorySlider{
    width: 150px !important;
    margin-left: -175px;
    margin-top: -150px;
  }
  .sliderDiv {
    margin-bottom: -100px;
    width: 320px;
    height: 200px;
    font-size: 20px;
    background-color: white;
    margin: 0 auto;
    font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
  }
  .CategorySlider2{
    width: 200px !important;
    height: 300px !important;
    margin-right: 400px !important;
  }
  .sliderline {
    width: 115px;
    height: 4px;
    background-color: black;
  }
  .third-contanier {
    width: 100%;
    background: #fafafa;
    height: 100vh;
  }
  .third-contanier img{
    width: 200px;
  }
  .third-shirt img{
    width: 75% !important;
  }
  .OurProducts {
    width: 100%;
    background: white;
  }
  .OurProducts2 {
    width: 130% !important;
    margin-left: -45px !important;
    background: white;
  }
  
  .OurProducts h3 {
    font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  .starIcon{
    width: 25px;
  }
  .OurProducts h3::after {
    content: "";
    position: absolute;
    width: 25%;
    height: 4px;
    background-color: black;
    top: 50%;
    right: -20px;
  }
  .OurProducts h3::before {
    content: "";
    position: absolute;
    width: 25%;
    height: 4px;
    background-color: black;
    top: 50%;
    left: -20px;
  }
  .review-slider h3 {
    font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: center;
    position: relative;
    font-size: 20px;
    font-weight: bold;
  }
  .review-slider h3::after {
    content: "";
    position: absolute;
    width: 10%;
    height: 4px;
    background-color: black;
    top: 50%;
    right: -5px;
  }
  .review-slider h3::before {
    content: "";
    position: absolute;
    width: 10%;
    height: 4px;
    background-color: black;
    top: 50%;
    left: -5px;
  }

  .subscribeCard h4 { 
    font-size: 18px;
  }
  .subscribeCard p { 
    font-size: 14px;
  }
  .subscribeCard a { 
    font-size: 14px;
  }
  .subscribe img{
    width: 200px;
    margin: 0 auto;
  }
  .subscribe button {
    background: #044f5c;
    padding: 10px 25px;
    font-size: 13px;
    margin-bottom: 50px;
  }
  .kanzalogoFooter{
    width: 100px !important;
  }
  .footerText{
    display: flex !important;
    justify-content: space-around !important;
    margin-right: 20px;
  }
  .shape2 {
    background: #fafafa;
    padding: 0 30px;
  }
  .review p {
    font-size: .875rem;
  }
  .saleCard {
    width: 23%;
    height: 30px;
    background-color: #da3f3f;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    top: 0;
    left: 75%;
    margin-right: 50px;
    font-size: 12px;
    text-align: center;
  }
  .Related h3 {
    font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: center;
  }
  .Related h3::after {
    content: "";
    position: absolute;
    width: 20%;
    height: 4px;
    background-color: black;
    top: 50%;
    right: -20px;
  }
  .Related h3::before {
    content: "";
    position: absolute;
    width: 20%;
    height: 4px;
    background-color: black;
    top: 50%;
    left: -20px;
  }
  .address-w{
    width: 100% !important;
    font-size: 10px !important;
    padding: 9px 20px !important;
  }
  .cover-text {
    color: white;
    font-size: 30px !important;
    font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
  }
/* .product-img{
  min-height: 250px !important;
  max-height: 250px !important;
} */
.shop:hover .dropdown-content {
  display: none !important;
}
}
@media screen and (max-width : 1400px) and (min-width : 1000px)  {
  .dropdown-items-profile h4 {
    font-size: 13px !important;
    margin-right: 50px !important;
    display: flex !important;
  }
}
@media screen and (max-width: 1000px) and (min-width: 762px)  {
  
  .aboutusimgs{
    width: 200px;
  }
  .aboutusText{
    font-size: 13px;
  }
  .sliderdetails{
    display: none !important;
  }
  .navIcons{
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-bottom: .625rem;
  }
  .CategorySlider{
    margin-top: -150px;
    margin-left: -100px;
  }
  .product{
    width: 50%;
  
  }
  .sliderDiv {
    margin-bottom: -100px;
    width: 350px;
    height: 200px;
    font-size: 20px;
    background-color: white;
    margin-right: -50px;
    font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
  }


  .OurProducts {
    width: 80%;
    background: white;
  }
  .OurProducts h3 {
    font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  .OurProducts h3::after {
    content: "";
    position: absolute;
    width: 35%;
    height: 4px;
    background-color: black;
    top: 50%;
    right: -20px;
  }
  .OurProducts h3::before {
    content: "";
    position: absolute;
    width: 35%;
    height: 4px;
    background-color: black;
    top: 50%;
    left: -20px;
  }
  .review-slider h3 {
    font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: center;
    position: relative;
    font-size: 20px;
    font-weight: bold;
  }
  .review-slider h3::after {
    content: "";
    position: absolute;
    width: 30%;
    height: 4px;
    background-color: black;
    top: 50%;
    right: -5px;
  }
  .review-slider h3::before {
    content: "";
    position: absolute;
    width: 30%;
    height: 4px;
    background-color: black;
    top: 50%;
    left: -5px;
  }
  .review-slider-div{
    width: 100% !important; 
  }
  .subscribeCard h4 { 
    font-size: 18px;
  }
  .subscribeCard p { 
    font-size: 14px;
  }
  .subscribeCard a { 
    font-size: 14px;
  }
  .subscribe img{
    width: 200px;
    margin: 0 auto;
  }
  .shape2 {
    background: #fafafa;
    padding: 15px 30px;
  }
  .review p {
    font-size: .875rem;
  }
  .saleCard {
    width: 55px;
    height: 27px;
    background-color: #da3f3f;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    top: 0;
    right: 20px !important;
    font-size: 12px;
    text-align: center;
  }
}
@media screen and (max-width: 1200px) and (min-width: 1000px) {
  .ProductDetailsVerticalSlider{
    height: 50px !important;
    padding-bottom: 150px !important;
  }
  .mySwiper2{
    margin-bottom: 150px !important;
    height: 300px;
    
    margin-right: 150px !important;
  }
  .navIcons{
    display: flex;
    justify-content: center;
    flex-direction: row;
    padding-bottom: .625rem;
  }
  .CategorySlider{
    margin-top: -150px;
    margin-left: -100px;
  }
  .product{
    width: 50%;
  
  }
  .sliderDiv {
    margin-bottom: -100px;
    width: 350px;
    height: 200px;
    font-size: 20px;
    background-color: white;
    margin-right: -50px;
    font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
  }


  .OurProducts {
    width: 80%;
    background: white;
  }
  .OurProducts h3 {
    font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: center;
    font-size: 20px;
    font-weight: bold;
  }
  .OurProducts h3::after {
    content: "";
    position: absolute;
    width: 35%;
    height: 4px;
    background-color: black;
    top: 50%;
    right: -20px;
  }
  .OurProducts h3::before {
    content: "";
    position: absolute;
    width: 35%;
    height: 4px;
    background-color: black;
    top: 50%;
    left: -20px;
  }
  .review-slider h3 {
    font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: center;
    position: relative;
    font-size: 20px;
    font-weight: bold;
  }
  .review-slider h3::after {
    content: "";
    position: absolute;
    width: 30%;
    height: 4px;
    background-color: black;
    top: 50%;
    right: -5px;
  }
  .review-slider h3::before {
    content: "";
    position: absolute;
    width: 30%;
    height: 4px;
    background-color: black;
    top: 50%;
    left: -5px;
  }
  /* .review-slider-div{
    width: 100% !important; 
  } */
  .subscribeCard h4 { 
    font-size: 18px;
  }
  .subscribeCard p { 
    font-size: 14px;
  }
  .subscribeCard a { 
    font-size: 14px;
  }
  .subscribe img{
    width: 200px;
    margin: 0 auto;
  }
  .shape2 {
    background: #fafafa;
    padding: 15px 30px;
  }
  .review p {
    font-size: .875rem;
  }
  .saleCard {
    width: 55px;
    height: 27px;
    background-color: #da3f3f;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    top: 0;
    right: 20px !important;
    font-size: 12px;
    text-align: center;
  }
  .Related h3 {
    font-family: againstRegular, "Open Sans", "Helvetica Neue", sans-serif;
    text-align: center;
  }
  .Related h3::after {
    content: "";
    position: absolute;
    width: 35%;
    height: 4px;
    background-color: black;
    top: 50%;
    right: -20px;
  }
  .Related h3::before {
    content: "";
    position: absolute;
    width: 35%;
    height: 4px;
    background-color: black;
    top: 50%;
    left: -20px;
  }
  
  .ProductDetails   .saleCard {
    width: 55px;
    height: 27px;
    background-color: #da3f3f;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    position: absolute;
    top: 0;
    right: 95px !important;
    font-size: 12px;
    text-align: center;
  }
  .ProductDetails .product {
    margin: 0 0;
    padding: 0 50PX;
  }
 
}